html {
    position: relative;
    min-height: 100%;
}

body {
    padding-bottom: 300px;
    padding-top: 56px;
    user-select: text;
    overflow-x: hidden;
}